<template>
  <div class="pa-4 pb-16 pb-sm-4">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <h2 class="primary--text font-weight-regular">แผนรายการประมาณการ</h2>
        <v-btn
          class="primary elevation-2 white--text rounded ml-2"
          icon
          tile
          @click="filterMaintenancePlanDialog = true"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          v-if="$store.getters.manage_maintenance"
          class="white--text ml-auto green d-none d-sm-flex"
          @click="addNewMaintenancePlan()"
        >
          <v-icon left> mdi-plus </v-icon>
          เพิ่มข้อมูล
        </v-btn>
      </div>
    </div>
    <v-btn
      class="white--text ml-auto green mb-6 hidden-sm-and-up"
      fixed
      right
      bottom
      fab
      @click="addNewMaintenancePlan()"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-2 rounded row-pointer data-maintenance-table"
        multi-sort
        :footer-props="{
          'items-per-page-text': 'จำนวนแถว',
          'items-per-page-options': [50, 100, -1]
        }"
        :loading="isLoading"
        @click:row="clickPlan"
        sort-by="budget_year"
        sort-desc
      >
        <template v-slot:item.kmRange="{ item }">
          {{
            !item.km_start && !item.km_end
              ? '-'
              : `${getKm(item.km_start)} - ${getKm(item.km_end)}`
          }}
        </template>
        <template v-slot:item.directions="{ item }">
          {{ item.directions ? item.directions : '-' }}
        </template>
        <template v-slot:item.amount_budget="{ item }">
          {{
            item.amount_budget
              ? parseFloat(item.amount_budget).toLocaleString()
              : 0
          }}
        </template>
      </v-data-table>
    </div>
    <FilterMaintenancePlanDialog
      v-model="filterMaintenancePlanDialog"
      @filter="getMaintenancePlanList"
      @close="filterMaintenancePlanDialog = false"
    />
    <EditMaintenancePlanDialog
      v-model="editMaintenancePlanDialog"
      :maintenancePlanInfo="maintenancePlanInfo"
      :isAdd="isAdd"
      @update="updateMaintenancePlan"
      @close="editMaintenancePlanDialog = false"
    />
  </div>
</template>

<script>
import FilterMaintenancePlanDialog from './dialog/FilterMaintenancePlanDialog.vue'
import EditMaintenancePlanDialog from './dialog/EditMaintenancePlanDialog.vue'
import utils from '@/assets/js/utils'
export default {
  components: {
    FilterMaintenancePlanDialog,
    EditMaintenancePlanDialog
  },
  data: () => ({
    headers: [
      {
        text: 'ปีงบประมาณ',
        align: 'center',
        value: 'budget_year',
        class: 'primary rounded-tl white--text text-no-wrap'
      },
      {
        text: 'หมายเลขทางหลวง',
        align: 'center',
        value: 'road_code',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'รหัสงาน',
        align: '',
        value: 'maintenance_code',
        class: 'primary white--text justify-start text-no-wrap'
      },
      {
        text: 'งาน',
        align: '',
        sortable: false,
        value: 'maintenance_text',
        class: 'primary white--text justify-start text-no-wrap'
      },
      {
        text: 'ช่วง กม.',
        align: 'center',
        sortable: false,
        value: 'kmRange',
        class: 'primary white--text',
        width: '15%'
      },
      {
        text: 'ทิศทาง',
        align: 'center',
        sortable: false,
        value: 'directions',
        class: 'primary white--text',
        width: '15%'
      },
      {
        text: 'งบประมาณ (บาท)',
        align: 'end',
        sortable: false,
        value: 'amount_budget',
        class: 'primary white--text rounded-tr text-no-wrap',
        width: '10%'
      }
    ],
    items: [],
    filterMaintenancePlanDialog: false,
    editMaintenancePlanDialog: false,
    maintenancePlanInfo: {},
    isAdd: false,
    itemLists: {},
    getKm: utils.getKm,
    isLoading: true
  }),
  async mounted () {
    await this.getRef('maintenance')
    await this.getRef('road')
    await this.getMaintenancePlanList({})
  },
  methods: {
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getMaintenancePlanList (filter) {
      this.isLoading = true
      this.filterMaintenancePlanDialog = false
      const items = await this.$store.dispatch(
        'maintenance/getMaintenancePlanList',
        { filter }
      )
      for (const item of items) {
        const maintenance = this.itemLists.maintenance.find(
          (maintenance) => maintenance.id === item.ref_maintenance_id
        )
        item.maintenance_text = maintenance.name
        item.maintenance_code = maintenance.ref_maintenance_group_id
        item.maintenance_direction = '-'

        item.bridge_ids = item.bridge_ids.map((bridge) => {
          return parseInt(bridge)
        })
        item.road_code = parseInt(item.road_code) || '-'
      }
      this.items = items
      this.isLoading = false
    },
    clickPlan (plan) {
      this.isAdd = false
      this.maintenancePlanInfo = plan
      this.editMaintenancePlanDialog = true
    },
    async updateMaintenancePlan () {
      this.editMaintenancePlanDialog = false
      await this.getMaintenancePlanList({})
    },
    addNewMaintenancePlan () {
      this.isAdd = true
      this.maintenancePlanInfo = {}
      this.editMaintenancePlanDialog = true
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

<style>
.theme--light.v-data-table.data-maintenance-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
.theme--light.v-data-table.data-maintenance-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.5);
}
.theme--light.v-data-table.data-maintenance-table
  .v-data-table-header__sort-badge {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
}
</style>
