<template>
  <div>
    <EditTemplateDialog
      v-model="isOpenDialog"
      title="แผนรายการประมาณการ"
      :is-add="isAdd"
      :isUpdateLoading="isLoading"
      @delete="deleteMaintenance()"
      @clear="clearForm()"
      @close="close()"
      @confirm="confirm()"
    >
      <div class="pa-4">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row>
            <v-col
              v-for="(form, formindex) in fromEditField"
              :key="formindex"
              :sm="form.col"
              cols="12"
            >
              <div class="my__v-subheader mb-1 form-text">
                {{ form.name }}
                <span v-if="form.isrequired" class="red--text"> * </span>
              </div>
              <v-select
                v-if="form.model === 'ref_maintenance_id'"
                v-model="editForm[form.model]"
                placeholder="เลือก"
                outlined
                dense
                hide-details="auto"
                :no-data-text="
                  form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                "
                :items="maintenanceByGroup"
                :item-text="form.select['item-text']"
                :item-value="form.select['item-value']"
                clearable
                :disabled="isLoading"
                :rules="form.isrequired ? [rules.required] : []"
              ></v-select>
              <v-text-field
                v-else-if="form.type === 'text'"
                v-model="editForm[form.model]"
                outlined
                dense
                hide-details="auto"
                :disabled="isLoading"
                :rules="form.isrequired ? [rules.required] : []"
              ></v-text-field>
              <v-text-field
                v-else-if="form.type === 'number'"
                v-model="editForm[form.model]"
                class="right-input"
                type="number"
                :placeholder="form.placeholder ? form.placeholder : '0'"
                min="0"
                outlined
                dense
                hide-details="auto"
                :disabled="isLoading"
                :rules="form.isrequired ? [rules.required] : []"
              ></v-text-field>
              <v-textarea
                v-else-if="form.type === 'textarea'"
                v-model="editForm[form.model]"
                type="number"
                outlined
                dense
                hide-details="auto"
                :disabled="isLoading"
                :rules="form.isrequired ? [rules.required] : []"
              ></v-textarea>
              <v-autocomplete
                v-else-if="form.model === 'bridge_ids'"
                v-model="editForm[form.model]"
                outlined
                dense
                multiple
                clearable
                hide-details="auto"
                placeholder="เลือก"
                :rules="[rules.requiredAutocomplete]"
                :items="bridgeListBySectionPart"
                :item-text="form.autocomplete['item-text']"
                :item-value="form.autocomplete['item-value']"
                :disabled="isLoading"
                no-data-text="ไม่มีสะพานให้เลือก"
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="index === 0">
                    <span>
                      {{ item.name }}
                      {{ item.direction ? `(${item.direction})` : '' }}
                    </span>
                  </div>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ editForm[form.model].length - 1 }} รายการ)
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    {{ item.name }}
                    {{ item.direction ? `(${item.direction})` : '' }}
                  </div>
                </template>
              </v-autocomplete>
              <v-select
                v-else-if="form.model === 'section_part_id'"
                v-model="editForm[form.model]"
                placeholder="เลือก"
                outlined
                dense
                hide-details="auto"
                :items="itemLists[form.select.name]"
                :item-text="form.select['item-text']"
                :item-value="form.select['item-value']"
                :loading="isLoadingSectionPart"
                clearable
                :disabled="isLoading"
                :no-data-text="
                  form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                "
                :rules="form.isrequired ? [rules.required] : []"
              ></v-select>
              <v-select
                v-else-if="form.type === 'select'"
                v-model="editForm[form.model]"
                placeholder="เลือก"
                outlined
                dense
                hide-details="auto"
                :items="itemLists[form.select.name]"
                :item-text="form.select['item-text']"
                :item-value="form.select['item-value']"
                clearable
                :disabled="isLoading"
                :no-data-text="
                  form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                "
                :rules="form.isrequired ? [rules.required] : []"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </EditTemplateDialog>
    <ConfirmDialog
      v-model="isConfirmEditDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import EditTemplateDialog from './EditTemplateDialog.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
import utils from '@/assets/js/utils'
export default {
  components: {
    EditTemplateDialog,
    ConfirmDialog
  },
  props: {
    value: Boolean,
    maintenancePlanInfo: Object,
    isAdd: Boolean
  },
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        this.editForm = this.maintenancePlanInfo
        if (!this.isAdd) {
          this.editForm.ref_maintenance_group_id =
            this.itemLists.maintenance.find(
              (maintenance) =>
                maintenance.id === this.maintenancePlanInfo.ref_maintenance_id
            ).ref_maintenance_group_id
          this.editForm = { ...this.editForm }
        } else {
          if (this.$refs.form) this.$refs.form.reset()
          this.editForm = {}
        }
      }
    },
    'editForm.ref_maintenance_group_id' (newVal, oldVal) {
      if (newVal) {
        const isMaintenanceInclude = this.maintenanceByGroup.find(
          (maintenance) => maintenance.id === this.editForm.ref_maintenance_id
        )
        if (!isMaintenanceInclude) this.editForm.ref_maintenance_id = null
      }
    },
    'editForm.road_id': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.getSectionPart(newVal)
        } else {
          delete this.editForm.section_part_id
          this.itemLists.control = []
        }
      }
    },
    bridgeListBySectionPart (newVal, oldVal) {
      this.editForm.bridge_ids = (this.editForm.bridge_ids || []).filter(
        (bridge) => newVal.some((id) => id.id === bridge)
      )
    }
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmEditDialog: false,
    getKm: utils.getKm,
    fromEditField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budget_year',
        placeholder: (new Date().getFullYear() + 543).toString(),
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'แผนรายการประมาณการ',
        model: 'ref_maintenance_plan_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'maintenance_plan',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'รหัสงาน',
        model: 'ref_maintenance_group_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'maintenance_group',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'งาน',
        model: 'ref_maintenance_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'maintenanceByGroup',
          'item-text': 'name',
          'item-value': 'id'
        },
        nodata: 'โปรดเลือกรหัสงาน'
      },
      {
        name: 'ปริมาณงาน',
        model: 'amount_task',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'หน่วย',
        model: 'ref_maintenance_unit_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'maintenance_unit',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'งบประมาณ (บาท)',
        model: 'amount_budget',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'จ้างเหมา/ดำเนินการเอง',
        model: 'ref_maintenance_jd_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'maintenance_jd',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'ลักษณะผิวทางมาตรฐาน',
        model: 'ref_surface_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'surface',
          'item-text': 'name',
          'item-value': 'id'
        }
      },
      {
        name: 'AADT (คัน/วัน)',
        model: 'aadt',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ระยะทำการ',
        model: 'maintenance_note',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'textarea'
      },
      {
        name: 'อายุบริการ (ปี)',
        model: 'year_service',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'road',
          'item-text': 'label',
          'item-value': 'road_id'
        }
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'control',
          'item-text': 'label',
          'item-value': 'section_part_id'
        },
        nodata: 'โปรดเลือกหมายเลขทางหลวง',
        isrequired: true
      },
      {
        name: 'สะพาน',
        model: 'bridge_ids',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        isrequired: true,
        autocomplete: {
          name: 'bridgeList',
          'item-text': 'name',
          'item-value': 'id'
        }
        // isrequired: true
      },
      {
        name: 'หมายเหตุ',
        model: 'remarks',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'textarea'
      }
    ],
    editForm: {
      ref_maintenance_group: null
    },
    itemLists: {},
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล',
      requiredAutocomplete: (v) => !!(v && v.length) || 'กรุณากรอกข้อมูล'
    },
    isLoading: false,
    isLoadingSectionPart: false
  }),
  computed: {
    maintenanceByGroup () {
      if (
        this.editForm.ref_maintenance_group_id ||
        this.editForm.ref_maintenance_id
      ) {
        return this.itemLists.maintenance.filter(
          (maintenance) =>
            maintenance.ref_maintenance_group_id ===
            this.editForm.ref_maintenance_group_id
          // '32000'
        )
      } else return []
    },
    bridgeListBySectionPart () {
      if (this.editForm.section_part_id) {
        const bridgeList = this.itemLists.bridgeList.filter(
          (bridge) =>
            bridge.section_part_id === parseInt(this.editForm.section_part_id)
        )
        return bridgeList
      } else {
        return []
      }
    }
  },
  mounted () {
    this.getRefRoad()
    this.getRef('maintenance')
    this.getRef('maintenance_group')
    this.getRef('maintenance_jd')
    this.getRef('maintenance_plan')
    this.getRef('maintenance_unit')
    this.getRef('surface')
    this.getBridgeList()
  },
  methods: {
    confirm () {
      if (this.$refs.form.validate()) {
        this.isConfirmEditDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },
    async edit () {
      this.isLoading = true
      let isEdit = null
      this.isConfirmEditDialog = false
      const editForm = { ...this.editForm }
      editForm.budget_year -= 543
      if (this.isAdd) {
        editForm.id = 0
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenancePlan',
          editForm
        )
      } else {
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenancePlan',
          editForm
        )
      }
      if (isEdit) {
        this.isLoading = false
        this.$emit('update')
      }
    },
    confirmDialog () {
      console.log(this.editForm)
    },
    close () {
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmEditDialog = false
    },
    async deleteMaintenance () {
      const isDelete = await this.$store.dispatch(
        'maintenance/delMaintenancePlan',
        {
          id: this.editForm.id
        }
      )
      if (isDelete) {
        this.$emit('update')
      }
    },
    async getRefRoad () {
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only'
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      this.itemLists.road = roads
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getSectionPart (roadid) {
      this.isLoadingSectionPart = true
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
      const checkSection = controls.find(
        (control) => control.section_part_id === this.editForm.section_part_id
      )
      if (!checkSection) {
        delete this.editForm.section_part_id
      }
      this.isLoadingSectionPart = false
    },
    clearForm () {
      this.editForm = {}
      this.$refs.form.reset()
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`,
          section_part_id: section.section_part_id
        })
        this.itemLists.bridgeList.push(
          ...section.items.map((b) => ({
            ...b,
            name: `[${this.getKm(b.km)}] ${b.name}`,
            section_part_id: section.section_part_id
          }))
        )
      })
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
